import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { StyledBase } from '@twilio-paste/theme';
import { Text } from '@twilio-paste/text';
import { Stack } from '@twilio-paste/stack';
import Changelog from '@twilio-paste/tabs-primitive/CHANGELOG.md';
import { useTabPrimitiveState, TabPrimitive, TabPrimitiveList, TabPrimitivePanel, TabPrimitiveStateReturn } from '@twilio-paste/tabs-primitive';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { horizontalExample, verticalExample, styledExample } from '../../../component-examples/TabsPrimitiveExamples';
export const pageQuery = graphql`
  {
    allPastePrimitive(filter: {name: {eq: "@twilio-paste/tabs-primitive"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/primitives/tabs-primitive/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Tabs Primitive" categoryRoute={SidebarCategoryRoutes.PRIMITIVES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/primitives/tabs" storybookUrl="/?path=/story/primitives-tabs--horizontal-tabs" data={props.data.allPastePrimitive.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About the Tabs Primitive`}</h3>
        <p>{`The Tabs primitive is an unstyled, functional version of a tabset component. It can be used to build a component following the `}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/wai-aria-practices/#tabpanel"
          }}>{`WAI-ARIA Tabs Pattern`}</a>{`. Our Tabs component is built on top of this primitive.`}</p>
        <p>{`This unstyled primitive is to be used when the styled `}<a parentName="p" {...{
            "href": "/components/tabs"
          }}>{`Tabs`}</a>{` provided by Paste doesn`}{`’`}{`t meet the requirements needed to solve a unique customer problem. At that point, you are welcome to fall back to this functional primitive to roll your own styled Tabs while still providing a functional and accessible experience to your customers.`}</p>
        <p>{`This primitive should be used to compose all custom Tabs to ensure accessibility and upgrade paths.`}</p>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle as="h3" mdxType="CalloutTitle">Before you roll your own tabset...</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    We strongly suggest that all components built on top of this primitive get reviewed by the Design Systems team and
    go through the UX Review process to ensure an excellent experience for our customers.
  </CalloutText>
        </Callout>
        <h3>{`Examples`}</h3>
        <h4>{`Horizontal Tabset`}</h4>
        <LivePreview scope={{
          useTabPrimitiveState,
          TabPrimitiveList,
          TabPrimitive,
          TabPrimitivePanel
        }} noInline mdxType="LivePreview">
  {horizontalExample}
        </LivePreview>
        <h4>{`Vertical Tabset`}</h4>
        <LivePreview scope={{
          useTabPrimitiveState,
          TabPrimitiveList,
          TabPrimitive,
          TabPrimitivePanel,
          Stack,
          Box
        }} noInline mdxType="LivePreview">
  {verticalExample}
        </LivePreview>
        <hr></hr>
        <h3>{`Styling a Custom Tabset`}</h3>
        <p>{`The tab primitive can be styled using Paste components and tokens. By using the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop, we can
change the underlying element and combine it with another component. In the example below we're combining
the `}<inlineCode parentName="p">{`TabPrimitive`}</inlineCode>{` with a custom `}<inlineCode parentName="p">{`Box`}</inlineCode>{` component. We are rendering the `}<inlineCode parentName="p">{`TabPrimitive`}</inlineCode>{` as `}<inlineCode parentName="p">{`CustomTab`}</inlineCode>{`, passing down
the necessary props from the state hook and combining those with `}<inlineCode parentName="p">{`Box`}</inlineCode>{` styling props.`}</p>
        <LivePreview scope={{
          useTabPrimitiveState,
          TabPrimitiveList,
          TabPrimitive,
          TabPrimitivePanel,
          Stack,
          Box
        }} noInline mdxType="LivePreview">
  {styledExample}
        </LivePreview>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <p>{`This package is a wrapper around the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/tab/"
          }}>{`Reakit Tab`}</a>{`. If you`}{`’`}{`re wondering why we wrapped that package into our own, we reasoned that it would be best for our consumers`}{`’`}{` developer experience. For example:`}</p>
        <ul>
          <li parentName="ul">{`By wrapping a library in `}<inlineCode parentName="li">{`@twilio-paste/x-primitive`}</inlineCode>{`, any update or swap of the underlying library would only be a version bump in the `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` file for the primitive. Without this, if we want to migrate the underlying library in the future, Twilio products that depend on this primitive would need to replace all occurrences of`}<inlineCode parentName="li">{`import … from 'x-package'`}</inlineCode>{`to`}<inlineCode parentName="li">{`import … from '@some-new/package'`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`We can more strictly enforce semver and backwards compatibility than some of our dependencies.`}</li>
          <li parentName="ul">{`We can control when to provide an update and which versions we allow, to help reduce potential bugs our consumers may face.`}</li>
          <li parentName="ul">{`We can control which APIs we expose. For example, we may chose to enable or disable usage of certain undocumented APIs.`}</li>
        </ul>
        <h3>{`Installation`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/tabs-primitive - or - yarn add @twilio-paste/core
`}</code></pre>
        <h3>{`Usage`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {
  useTabPrimitiveState,
  TabPrimitiveList,
  TabPrimitive,
  TabPrimitivePanel,
} from '@twilio-paste/core/tabs-primitive';

const HorizontalExample = () => {
  const tab = useTabPrimitiveState();
  return (
    <>
      <TabPrimitiveList {...tab} aria-label="My tabs">
        <TabPrimitive {...tab}>Tab 1</TabPrimitive>
        <TabPrimitive {...tab} disabled>
          Tab 2 (disabled)
        </TabPrimitive>
        <TabPrimitive {...tab}>Tab 3</TabPrimitive>
      </TabPrimitiveList>
      <TabPrimitivePanel {...tab}>Tab 1</TabPrimitivePanel>
      <TabPrimitivePanel {...tab}>Tab 2</TabPrimitivePanel>
      <TabPrimitivePanel {...tab}>Tab 3</TabPrimitivePanel>
    </>
  );
};
`}</code></pre>
        <h3>{`API`}</h3>
        <p>{`This props list is a scoped version of the properties available from the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/tab/"
          }}>{`Reakit Tab`}</a>{` package.`}</p>
        <h4>{`useTabState`}</h4>
        <h5>{`baseId `}<inlineCode parentName="h5">{`string`}</inlineCode></h5>
        <p>{`ID that will serve as a base for all the items IDs.`}</p>
        <h5>{`rtl `}<inlineCode parentName="h5">{`boolean`}</inlineCode></h5>
        <p>{`Determines how `}<inlineCode parentName="p">{`next`}</inlineCode>{` and `}<inlineCode parentName="p">{`previous`}</inlineCode>{` functions will behave. If `}<inlineCode parentName="p">{`rtl`}</inlineCode>{` is
set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, they will be inverted. You still need to set `}<inlineCode parentName="p">{`dir="rtl"`}</inlineCode>{` on
HTML/CSS.`}</p>
        <h5>{`orientation `}<inlineCode parentName="h5">{`"horizontal" | "vertical" | undefined`}</inlineCode></h5>
        <p>{`Defines the orientation of the composite widget. If the composite has a
single row or column (one-dimensional), the `}<inlineCode parentName="p">{`orientation`}</inlineCode>{` value determines
which arrow keys can be used to move focus:`}</p>
        <p>{`It doesn't have any effect on two-dimensional composites.`}</p>
        <h5>{`currentId `}<inlineCode parentName="h5">{`string | null | undefined`}</inlineCode></h5>
        <p>{`The current focused item `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
        <h5>{`loop `}<inlineCode parentName="h5">{`boolean | "horizontal" | "vertical"`}</inlineCode></h5>
        <p>{`Whether keyboard navigation loops back to the beginning.`}</p>
        <h5>{`wrap `}<inlineCode parentName="h5">{`boolean | "horizontal" | "vertical"`}</inlineCode></h5>
        <p>{`If enabled, moving to the next item from the last one in a row or column
will focus the first item in the next row or column and vice-versa.`}</p>
        <h5>{`selectedId `}<inlineCode parentName="h5">{`string | null | undefined`}</inlineCode></h5>
        <p>{`The current selected tab's `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
        <h5>{`manual `}<inlineCode parentName="h5">{`boolean`}</inlineCode></h5>
        <p>{`Whether the tab selection should be manual.`}</p>
        <hr></hr>
        <h4>{`Tab`}</h4>
        <h5>{`disabled `}<inlineCode parentName="h5">{`boolean | undefined`}</inlineCode></h5>
        <p>{`Same as the HTML attribute.`}</p>
        <h5>{`focusable `}<inlineCode parentName="h5">{`boolean | undefined`}</inlineCode></h5>
        <p>{`When an element is `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, it may still be `}<inlineCode parentName="p">{`focusable`}</inlineCode>{`. It works
similarly to `}<inlineCode parentName="p">{`readOnly`}</inlineCode>{` on form elements. In this case, only
`}<inlineCode parentName="p">{`aria-disabled`}</inlineCode>{` will be set.`}</p>
        <h5>{`id `}<inlineCode parentName="h5">{`string | undefined`}</inlineCode></h5>
        <p>{`Same as the HTML attribute.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">State props</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    These props are returned by the state hook. You can spread them into this component ({`...state`}) or pass them
    separately. You can also provide these props from your own state logic.
  </CalloutText>
        </Callout>
        <h5>{`baseId `}<inlineCode parentName="h5">{`string`}</inlineCode></h5>
        <p>{`ID that will serve as a base for all the items IDs.`}</p>
        <h5>{`orientation `}<inlineCode parentName="h5">{`"horizontal" | "vertical" | undefined`}</inlineCode></h5>
        <p>{`Defines the orientation of the composite widget. If the composite has a
single row or column (one-dimensional), the `}<inlineCode parentName="p">{`orientation`}</inlineCode>{` value determines
which arrow keys can be used to move focus:`}</p>
        <p>{`It doesn't have any effect on two-dimensional composites.`}</p>
        <h5>{`currentId `}<inlineCode parentName="h5">{`string | null | undefined`}</inlineCode></h5>
        <p>{`The current focused item `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
        <h5>{`items `}<inlineCode parentName="h5">{`Item[]`}</inlineCode></h5>
        <p>{`Lists all the composite items with their `}<inlineCode parentName="p">{`id`}</inlineCode>{`, DOM `}<inlineCode parentName="p">{`ref`}</inlineCode>{`, `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` state
and `}<inlineCode parentName="p">{`groupId`}</inlineCode>{` if any. This state is automatically updated when
`}<inlineCode parentName="p">{`registerItem`}</inlineCode>{` and `}<inlineCode parentName="p">{`unregisterItem`}</inlineCode>{` are called.`}</p>
        <h5>{`setCurrentId `}<inlineCode parentName="h5">{`(value: SetStateAction<string | null | undefined>) => void`}</inlineCode></h5>
        <p>{`Sets `}<inlineCode parentName="p">{`currentId`}</inlineCode>{`.`}</p>
        <h5>{`registerItem `}<inlineCode parentName="h5">{`(item: Item) => void`}</inlineCode></h5>
        <p>{`Registers a composite item.`}</p>
        <h5>{`unregisterItem `}<inlineCode parentName="h5">{`(id: string) => void`}</inlineCode></h5>
        <p>{`Unregisters a composite item.`}</p>
        <h5>{`next `}<inlineCode parentName="h5">{`(unstable_allTheWay?: boolean | undefined) => void`}</inlineCode></h5>
        <p>{`Moves focus to the next item.`}</p>
        <h5>{`previous `}<inlineCode parentName="h5">{`(unstable_allTheWay?: boolean | undefined) => void`}</inlineCode></h5>
        <p>{`Moves focus to the previous item.`}</p>
        <h5>{`up `}<inlineCode parentName="h5">{`(unstable_allTheWay?: boolean | undefined) => void`}</inlineCode></h5>
        <p>{`Moves focus to the item above.`}</p>
        <h5>{`down `}<inlineCode parentName="h5">{`(unstable_allTheWay?: boolean | undefined) => void`}</inlineCode></h5>
        <p>{`Moves focus to the item below.`}</p>
        <h5>{`first `}<inlineCode parentName="h5">{`() => void`}</inlineCode></h5>
        <p>{`Moves focus to the first item.`}</p>
        <h5>{`last `}<inlineCode parentName="h5">{`() => void`}</inlineCode></h5>
        <p>{`Moves focus to the last item.`}</p>
        <h5>{`manual `}<inlineCode parentName="h5">{`boolean`}</inlineCode></h5>
        <p>{`Whether the tab selection should be manual.`}</p>
        <h5>{`selectedId `}<inlineCode parentName="h5">{`string | null | undefined`}</inlineCode></h5>
        <p>{`The current selected tab's `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
        <h5>{`panels `}<inlineCode parentName="h5">{`Item[]`}</inlineCode></h5>
        <p>{`Lists all the panels.`}</p>
        <h5>{`select `}<inlineCode parentName="h5">{`(id: string | null) => void`}</inlineCode></h5>
        <p>{`Moves into and selects a tab by its `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
        <hr></hr>
        <h4>{`TabList`}</h4>
        <h5>{`disabled `}<inlineCode parentName="h5">{`boolean | undefined`}</inlineCode></h5>
        <p>{`Same as the HTML attribute.`}</p>
        <h5>{`focusable `}<inlineCode parentName="h5">{`boolean | undefined`}</inlineCode></h5>
        <p>{`When an element is `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, it may still be `}<inlineCode parentName="p">{`focusable`}</inlineCode>{`. It works
similarly to `}<inlineCode parentName="p">{`readOnly`}</inlineCode>{` on form elements. In this case, only
`}<inlineCode parentName="p">{`aria-disabled`}</inlineCode>{` will be set.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">State props</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    These props are returned by the state hook. You can spread them into this component ({`...state`}) or pass them
    separately. You can also provide these props from your own state logic.
  </CalloutText>
        </Callout>
        <h5>{`baseId `}<inlineCode parentName="h5">{`string`}</inlineCode></h5>
        <p>{`ID that will serve as a base for all the items IDs.`}</p>
        <h5>{`orientation `}<inlineCode parentName="h5">{`"horizontal" | "vertical" | undefined`}</inlineCode></h5>
        <p>{`Defines the orientation of the composite widget. If the composite has a
single row or column (one-dimensional), the `}<inlineCode parentName="p">{`orientation`}</inlineCode>{` value determines
which arrow keys can be used to move focus:`}</p>
        <h5>{`currentId `}<inlineCode parentName="h5">{`string | null | undefined`}</inlineCode></h5>
        <p>{`The current focused item `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
        <h5>{`wrap `}<inlineCode parentName="h5">{`boolean | "horizontal" | "vertical"`}</inlineCode></h5>
        <p>{`If enabled, moving to the next item from the last one in a row or column
will focus the first item in the next row or column and vice-versa.`}</p>
        <h5>{`groups `}<inlineCode parentName="h5">{`Group[]`}</inlineCode></h5>
        <p>{`Lists all the composite groups with their `}<inlineCode parentName="p">{`id`}</inlineCode>{` and DOM `}<inlineCode parentName="p">{`ref`}</inlineCode>{`. This state
is automatically updated when `}<inlineCode parentName="p">{`registerGroup`}</inlineCode>{` and `}<inlineCode parentName="p">{`unregisterGroup`}</inlineCode>{` are
called.`}</p>
        <h5>{`items `}<inlineCode parentName="h5">{`Item[]`}</inlineCode></h5>
        <p>{`Lists all the composite items with their `}<inlineCode parentName="p">{`id`}</inlineCode>{`, DOM `}<inlineCode parentName="p">{`ref`}</inlineCode>{`, `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` state
and `}<inlineCode parentName="p">{`groupId`}</inlineCode>{` if any. This state is automatically updated when
`}<inlineCode parentName="p">{`registerItem`}</inlineCode>{` and `}<inlineCode parentName="p">{`unregisterItem`}</inlineCode>{` are called.`}</p>
        <h5>{`move `}<inlineCode parentName="h5">{`(id: string | null) => void`}</inlineCode></h5>
        <p>{`Moves focus to a given item ID.`}</p>
        <h5>{`setCurrentId `}<inlineCode parentName="h5">{`(value: SetStateAction<string | null | undefined>) => void`}</inlineCode></h5>
        <p>{`Sets `}<inlineCode parentName="p">{`currentId`}</inlineCode>{`.`}</p>
        <h5>{`first `}<inlineCode parentName="h5">{`() => void`}</inlineCode></h5>
        <p>{`Moves focus to the first item.`}</p>
        <h5>{`last `}<inlineCode parentName="h5">{`() => void`}</inlineCode></h5>
        <p>{`Moves focus to the last item.`}</p>
        <hr></hr>
        <h4>{`TabPanel`}</h4>
        <h5>{`id `}<inlineCode parentName="h5">{`string | undefined`}</inlineCode></h5>
        <p>{`Same as the HTML attribute.`}</p>
        <h5>{`tabId `}<inlineCode parentName="h5">{`string | undefined`}</inlineCode></h5>
        <p>{`Tab's id`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">State props</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    These props are returned by the state hook. You can spread them into this component ({`...state`}) or pass them
    separately. You can also provide these props from your own state logic.
  </CalloutText>
        </Callout>
        <h5>{`baseId `}<inlineCode parentName="h5">{`string`}</inlineCode></h5>
        <p>{`ID that will serve as a base for all the items IDs.`}</p>
        <h5>{`visible `}<inlineCode parentName="h5">{`boolean`}</inlineCode></h5>
        <p>{`Whether it's visible or not.`}</p>
        <h5>{`animating `}<inlineCode parentName="h5">{`boolean`}</inlineCode></h5>
        <p>{`Whether it's animating or not.`}</p>
        <h5>{`animated `}<inlineCode parentName="h5">{`number | boolean`}</inlineCode></h5>
        <p>{`If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, `}<inlineCode parentName="p">{`animating`}</inlineCode>{` will be set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` when `}<inlineCode parentName="p">{`visible`}</inlineCode>{` is updated.
It'll wait for `}<inlineCode parentName="p">{`stopAnimation`}</inlineCode>{` to be called or a CSS transition ends.
If `}<inlineCode parentName="p">{`animated`}</inlineCode>{` is set to a `}<inlineCode parentName="p">{`number`}</inlineCode>{`, `}<inlineCode parentName="p">{`stopAnimation`}</inlineCode>{` will be called only
after the same number of milliseconds have passed.`}</p>
        <h5>{`stopAnimation `}<inlineCode parentName="h5">{`() => void`}</inlineCode></h5>
        <p>{`Stops animation. It's called automatically if there's a CSS transition.`}</p>
        <h5>{`selectedId `}<inlineCode parentName="h5">{`string | null | undefined`}</inlineCode></h5>
        <p>{`The current selected tab's `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
        <h5>{`items `}<inlineCode parentName="h5">{`Item[]`}</inlineCode></h5>
        <p>{`Lists all the composite items with their `}<inlineCode parentName="p">{`id`}</inlineCode>{`, DOM `}<inlineCode parentName="p">{`ref`}</inlineCode>{`, `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` state
and `}<inlineCode parentName="p">{`groupId`}</inlineCode>{` if any. This state is automatically updated when
`}<inlineCode parentName="p">{`registerItem`}</inlineCode>{` and `}<inlineCode parentName="p">{`unregisterItem`}</inlineCode>{` are called.`}</p>
        <h5>{`panels `}<inlineCode parentName="h5">{`Item[]`}</inlineCode></h5>
        <p>{`Lists all the panels.`}</p>
        <h5>{`registerPanel `}<inlineCode parentName="h5">{`(item: Item) => void`}</inlineCode></h5>
        <p>{`Registers a tab panel.`}</p>
        <h5>{`unregisterPanel `}<inlineCode parentName="h5">{`(id: string) => void`}</inlineCode></h5>
        <p>{`Unregisters a tab panel.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      